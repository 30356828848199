<template>
  <main v-if="page" class="layout-default">
    <component
      :is="HeaderModules[page.headerModule.__typename]"
      v-if="page.headerModule"
      class="header"
      :data="page.headerModule"
      :data-module="page.headerModule.__typename"
    >
    </component>
    <template v-for="module in page.contentModules">
      <component
        :is="ContentModules[module.__typename]"
        v-if="ContentModules[module.__typename]"
        :key="module.id"
        class="module"
        :data="module"
        :data-module="module.__typename"
      >
      </component>
    </template>
  </main>
</template>

<script setup lang="ts">
import { ContentModules } from '~/components/content-modules/ContentModules';
import { HeaderModules } from '~/components/header-modules/HeaderModules';
import { useSeo } from '~/utils/useSeo';
import type { GetPageByTranslatedSlugQuery } from '#gql';

const i18n = useI18n();

const route = useRoute();

const { data, error } = await useFetch<GetPageByTranslatedSlugQuery>('/api/content/page', {
  query: {
    slug: 'home',
    locale: i18n.localeProperties.value.siteLocale,
  },
});

const page = data?.value?.page ?? null;

if (!page || error.value) {
  showError({
    statusCode: 404,
  });
} else if (page) {
  useSeo(page._seoMetaTags ?? [], {
    customTitleTemplate: '%s',
  });

  route.meta.pageLink = {
    __typename: page.__typename,
    _allTranslatedSlugLocales: page._allTranslatedSlugLocales,
  };
}
</script>

<style scoped lang="scss">
.layout-default {
  width: 100%;
  display: grid;
  place-items: center;
}
</style>
